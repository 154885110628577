import React from 'react';

import { useRouter } from 'next/router';

import { Slash } from '@ctlyst.id/internal-icon';
import { Button, Card, Text, useToken } from '@ctlyst.id/internal-ui';
import getRuntimeEnv from '@utils/env';

const Home = () => {
	const { replace } = useRouter();

	const XMS_URL = getRuntimeEnv('XMS_URL');
	const [neutral600] = useToken('colors', ['neutral.600']);

	const handleBack = () => {
		replace(`${XMS_URL}/order`);
	};

	return (
		<Card
			w="100%"
			p="8"
			mt={3}
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			minH="500px"
		>
			<Slash size={100} color={neutral600} />
			<Text my="4" color="black.medium" w="330px" textAlign="center">
				Halaman yang dituju tidak ditemukan. Silakan pilih menu lainnya atau
				klik tombol kembali.
			</Text>
			<Button data-test-id="CT_page_404_back-button" onClick={handleBack}>
				Kembali
			</Button>
		</Card>
	);
};

export default Home;
